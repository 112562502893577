import { FC } from 'react';
import { Stack } from 'react-bootstrap';

import CircledText from './breadcrumb/CircledText';
import { Link } from 'react-router-dom';

type Props = {
  step: 1 | 2;
  goToStep1: () => void;
};

const Breadcrumb: FC<Props> = ({ step, goToStep1 }) => (
  <Stack direction="horizontal" className="px-3 py-3 align-middle m-0">
    <span className="my-auto">
      <Link to="/">
        <u>Home</u>
      </Link>{' '}
      {' -> '}
    </span>
    <CircledText>1</CircledText>{' '}
    <span className="my-auto">
      <Link to="/">
        <u>Log In</u>
      </Link>{' '}
      {' -> '}
    </span>
    <CircledText>2</CircledText>{' '}
    <span className="my-auto">
      {step === 2 ? (
        <Link onClick={goToStep1} to="/signup">
          <u>Sign Up</u> {' -> '}
        </Link>
      ) : (
        <span>Sign Up</span>
      )}
    </span>
    {step === 2 ? (
      <>
        <CircledText>3</CircledText> <span className="my-auto">State ID</span>
      </>
    ) : null}
  </Stack>
);

export default Breadcrumb;
