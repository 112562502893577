import { FC, HTMLProps, useState } from 'react';

export type noteSections = {
  id: number;
  name: string;
  value: string;
  noTitle?: boolean;
};

type IProps = {
  notes: { id: number; name: string; value: string; noTitle?: boolean }[];
} & HTMLProps<HTMLTextAreaElement>;

const NotesTextArea: FC<IProps> = ({ notes, ...rest }) => {
  const [onFocus, setOnFocus] = useState(false);

  const selected = () => setOnFocus(true);
  const deselected = () => setOnFocus(false);

  return (
    <div
      className={`text-area-mixed-readonly ${
        onFocus ? 'text-area-mixed-readonly__selected' : ''
      }`}
    >
      {notes.map((section, index) => (
        <div id="notesTextArea" key={section.id}>
          {!section.noTitle && section.name != '' && (
            <textarea
              onFocus={selected}
              onBlur={deselected}
              key={`textAreaTitle${index}`}
              className={'text-area-mixed-readonly__section-title'}
              rows={1}
              readOnly
              value={section.name + ':'}
            />
          )}
          <textarea
            onFocus={selected}
            id={`textArea${index}`}
            key={`textArea${section.name ? section.name : ''}${index}`}
            onBlur={deselected}
            className={'text-area-mixed-readonly__input-area'}
            defaultValue={notes[index].value}
            {...rest}
          />
        </div>
      ))}
    </div>
  );
};

const getNotesString = (): string => {
  const doc = document.getElementById('notesTextArea');
  const strings: string[] = [];
  doc.childNodes.forEach((val) => {
    strings.push((val as any).value);
  });
  return strings.join('\n');
};

export { getNotesString };
export default NotesTextArea;
