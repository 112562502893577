import React, { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';
import { ErrorMessage } from 'formik';

type Props = {
  name?: string;
  prompt?: string;
  initialFile?: string;
  onChange?: (base64File: string) => void;
  withFormikError?: boolean;
  renderer: (base64File: string, prompt?: string) => ReactNode;
};

const FileDropzone: React.FC<Props> = ({
  initialFile,
  renderer,
  name,
  prompt,
  onChange = () => {},
  withFormikError = false,
}) => {
  const [file, setFile] = React.useState<string>(initialFile);

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onload = () => {
          const fileAsBase64 = reader.result as string;
          setFile(fileAsBase64);
          onChange(fileAsBase64);
        };

        reader.readAsDataURL(file);
      });
    },
    [setFile, onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps({
        className: 'file-dropzone mb-4',
      })}
    >
      {renderer(file, prompt)}
      <input multiple={false} {...getInputProps()} accept="image/*" />
      {withFormikError && (
        <ErrorMessage
          name={name}
          render={(msg) => (
            <div className="invalid-feedback mb-3" style={{ display: 'block' }}>
              {msg}
            </div>
          )}
        />
      )}
    </div>
  );
};

export default FileDropzone;
