import { Col } from 'react-bootstrap';

import Breadcrumb from './signup/Breadcrumb';
import SignupForm from './signup/SignupForm';
import { useState } from 'react';

const Signup = () => {
  const [step, setStep] = useState<1 | 2>(1);

  return (
    <>
      <Breadcrumb step={step} goToStep1={() => setStep(1)}></Breadcrumb>

      <Col className="mx-auto text-center">
        <img
          className="ml-auto my-2"
          height="48"
          src="/app_logo.png"
          alt="MomentMD"
        />
        <h5>Sign Up</h5>
      </Col>

      <SignupForm step={step} setStep={setStep} />
    </>
  );
};

export default Signup;
