import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

import {
  Creators as SignupActions,
  SignUpFormValues,
  getSignupFormValidationSchema,
} from '../../modules/signup.module';
import { MyState } from '../../store';
import StepOne from './signup-form/StepOne';
import StepTwo from './signup-form/StepTwo';
import { auth } from '../../utils/Firebase';
import { trimStringObjectValues } from '../../utils/trimStringObjectValues';
import { API_URL } from '../../config';

const checkEmailForUnique = async (email: string) => {
  const check = await fetchSignInMethodsForEmail(auth, email);

  return check.length > 0;
};

type Props = {
  step: 1 | 2;
  setStep: (step: 1 | 2) => void;
  checkDataNpi: any;
} & typeof SignupActions;

const SignupForm: FC<Props> = ({
  requestSignup,
  step,
  setStep,
  checkDataNpi,
}) => {
  const [checkNpi, setCheckNpi] = useState('');
  const [credentials, setCredentials] = useState([]);

  useEffect(() => {
    setCheckNpi(checkDataNpi);
  }, [checkDataNpi, checkNpi]);

  const onSubmit = async (values, { setSubmitting, setFieldError }) => {
    if (step === 1) {
      const isEmailAlreadyInUse = await checkEmailForUnique(values.email);

      if (isEmailAlreadyInUse) {
        setFieldError(
          'email',
          'There is already a user registered with that email',
        );
        setSubmitting(false);
        return;
      }

      if (checkNpi !== '') {
        if (window.confirm(checkNpi)) {
          setStep(2);
        }
      } else {
        setStep(2);
      }
    } else {
      values['state'] = values['state'].toUpperCase();
      requestSignup(trimStringObjectValues(values));
    }

    setSubmitting(false);
  };

  useEffect(() => {
    (async () => {
      const result = await fetch(
        `${API_URL}/credentials?__limit=10000&__sort=-updatedAt`,
        {
          method: 'GET',
        },
      );
      const res = await result.json();
      setCredentials(res.data);
    })();
  }, []);

  return (
    <Formik<SignUpFormValues>
      initialValues={{
        firstName: '',
        lastName: '',
        birthday: null,
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        credential: undefined,
        password: '',
        confirmPassword: '',
        npi: '',
        dea: '',
        pln: '',
        ssn: '',
        terms: 'false',
        licenseFront: '',
        licenseBack: '',
        signature: '',
      }}
      validationSchema={getSignupFormValidationSchema(credentials, step)}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue, isValid, dirty, errors }) => (
        <Form>
          {step === 1 && (
            <StepOne
              values={values}
              setFieldValue={setFieldValue}
              credentials={credentials}
            />
          )}
          {step === 2 && <StepTwo />}
          <Row className="justify-content-md-center">
            <Col xs lg="5">
              <Button
                type="submit"
                className="w-100 my-3"
                disabled={isSubmitting}
              >
                {step === 1 ? 'Next' : 'Register'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: MyState) => ({
  checkDataNpi: state.signup.isDataNpiCheck,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...SignupActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
