import {
  createSingleEventSaga,
  MyAction,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { take, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import startCase from 'lodash/startCase';

import { API_URL, AUTH_API_URL } from '../config';
import { Creators as LoadingActions } from './loading.module';
import { Creators as ErrorActions } from './errors.module';
import { auth, firestore, storage } from '../utils/Firebase';
import { noOpAction } from '../utils/noOpAction';
import { getCoordinatesForAddress } from '../utils/geocoding';
import { MMDError } from '../utils/MMDError';
import { PASSWORD, EMAIL } from '../utils/validation';
import { getExtension } from '../utils/getExtensionFromB64';
import moment from 'moment';
import isValidState from 'is-valid-state';
import store from '../store';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadString,
} from 'firebase/storage';
import {
  GeoPoint,
  Timestamp,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
} from 'firebase/firestore';
import Bugsnag from '@bugsnag/js';
import * as Yup from 'yup';

interface NpiDownPayload {
  npiDown: boolean;
}
interface IsDataNpiCheckPayload {
  isDataNpiCheck: string;
}

interface ActionTypes {
  REQUEST_SIGNUP: string;
  SUCCESS_SIGNUP: string;
  NPI_DOWN: string;
  IS_DATA_NPI_CHECK: string;
}

interface UserGeo {
  lat: number;
  lng: number;
}

interface UserDocData {
  file: string;
  path: string;
}

export interface SignUpFormValues {
  address: string;
  birthday: Date | null;
  city: string;
  confirmPassword: string;
  credential: string;
  dea: string;
  email: string;
  firstName: string;
  lastName: string;
  npi: string;
  password: string;
  phone: string;
  pln: string;
  ssn: string;
  state: string;
  terms: string;
  zipCode: string;
  signature: string;
  licenseBack: string;
  licenseFront: string;
}

interface ActionCreators {
  requestSignup: (payload: Dictionary<string>) => MyAction<Dictionary<string>>;
  successSignup: (...args: any[]) => MyAction<any>;
  npiDown: (payload: NpiDownPayload) => MyAction<NpiDownPayload>;
  isDataNpiCheck: (
    payload: IsDataNpiCheckPayload,
  ) => MyAction<IsDataNpiCheckPayload>;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestSignup: ['payload'],
  successSignup: [],
  npiDown: ['payload'],
  isDataNpiCheck: ['payload'],
});

const initialState = {
  npiDown: false,
  isDataNpiCheck: '',
};

export interface SignupState {
  npiDown: boolean;
  isDataNpiCheck: string;
}

export const signupReducer = createReducer<SignupState>(initialState, {
  [Types.NPI_DOWN]: changeNpiDown,
  [Types.IS_DATA_NPI_CHECK]: changeIsDataNpiCheck,
});

function changeNpiDown(state: SignupState, action: MyAction<NpiDownPayload>) {
  return {
    ...state,
    npiDown: action.payload.npiDown,
  };
}
function changeIsDataNpiCheck(
  state: SignupState,
  action: MyAction<IsDataNpiCheckPayload>,
) {
  return {
    ...state,
    isDataNpiCheck: action.payload.isDataNpiCheck,
  };
}

const deleteFileFromStorage = async ({ path }: UserDocData): Promise<void> => {
  try {
    const fileRef = ref(storage, path);

    await getDownloadURL(fileRef);
    await deleteObject(fileRef);
  } catch (error) {
    if (error.code === 'storage/object-not-found') {
      console.log('File does not exist.');
    } else {
      console.error('Error checking or deleting file: ', error);
    }
  }
};

const deleteUserDocsFromFirestore = async (
  currentUserUID: string,
): Promise<void> => {
  const userDocRef = doc(firestore, 'users', currentUserUID);

  try {
    const docSnapshot = await getDoc(userDocRef);
    if (docSnapshot.exists()) {
      await deleteDoc(userDocRef);
    }
  } catch (error) {
    console.error('Error checking or deleting document: ', error);
  }
};

const clearFirebase = async (
  email: string,
  password: string,
  userDocs: UserDocData[] | null,
) => {
  if (!auth.currentUser) {
    return;
  }

  Bugsnag.notify('Clearing user in firebase', (event) => {
    event.context = `signup PROVIDER ( clear firebase ) NOT ERROR JUST INFO!`;
    event.setUser(email, email);
    event.addMetadata('auth info', auth.currentUser);
  });

  try {
    await deleteUserDocsFromFirestore(auth.currentUser.uid);

    if (userDocs) {
      await Promise.all(userDocs.map(deleteFileFromStorage));
    }

    await reauthenticateWithCredential(
      auth.currentUser,
      EmailAuthProvider.credential(email, password),
    );

    await auth.currentUser.delete();
  } catch (error) {
    Bugsnag.notify(error, (event) => {
      event.context = `signup error ( clear firebase error ) ( ${
        error.message ?? 'unknown error'
      } )`;
      event.setUser(email, email);
    });
  }
};

const onSignUpError = async (
  error: any,
  email: string,
  password: string,
  userDocs: UserDocData[] | null,
): Promise<void> => {
  await clearFirebase(email, password, userDocs);

  Bugsnag.notify(error.originalError ?? error, (event) => {
    event.context = `signup PROVIDER error ( ${
      error.message ?? 'unknown error'
    } )`;
    event.setUser(email, email);
  });

  throw new MMDError(
    `Error creating User – ${error.message ?? 'unknown error'}`,
  );
};

const saveUserInAPI = async (
  userData: Partial<SignUpFormValues>,
): Promise<void> => {
  try {
    const { npiDown, isDataNpiCheck } = store.getState().signup;

    const token = await auth.currentUser.getIdToken();

    const headers = new Headers();
    headers.append('Authorization', token);
    headers.append('Content-Type', 'application/json');

    const result = await fetch(`${AUTH_API_URL}/doctors`, {
      headers,
      method: 'POST',
      body: JSON.stringify({
        ...userData,
        npiDown,
        isAddressCheck: isDataNpiCheck,
      }),
    });

    if (!result.ok) {
      throw new Error(`API error – ${JSON.stringify(result)}`);
    }
  } catch (err) {
    throw new MMDError('User cannot saved in the API', err);
  }
};

const saveUserDocsInFirebaseStorage = async (
  userDocs: UserDocData[],
): Promise<void> => {
  try {
    await Promise.all(
      userDocs.map(({ file, path }) =>
        uploadString(ref(storage, path), file, 'data_url'),
      ),
    );
  } catch (err) {
    throw new MMDError('Docs cannot be uploaded', err);
  }
};

const saveUserDataInFirestore = async ({
  lat,
  lng,
  birthday,
  ...userData
}: Partial<SignUpFormValues> & UserGeo): Promise<void> => {
  try {
    await setDoc(doc(firestore, 'users', auth.currentUser.uid), {
      ...userData,
      type: 'Doctor',
      id: auth.currentUser.uid,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      twoStepVerification: false,
      coordinates: new GeoPoint(lat, lng),
      birthday: moment(birthday, 'MM-DD-YYYY').format('MM-DD-YYYY'),
    });

    await setDoc(doc(firestore, 'doctorSecret', auth.currentUser.uid), {
      ssn: userData.ssn,
    });
  } catch (err) {
    throw new MMDError('Cannot set user data to firestore', err);
  }
};

const extractCoordinatesFromAddress = async (
  data: Pick<SignUpFormValues, 'address' | 'city' | 'state' | 'zipCode'>,
): Promise<UserGeo> => {
  try {
    return await getCoordinatesForAddress(
      `${data.address}, ${data.city}, ${data.state} ${data.zipCode}`,
    );
  } catch (err) {
    throw new MMDError('The address cannot be validated', err);
  }
};

const createUserFilesData = ({
  signature,
  licenseBack,
  licenseFront,
}: Pick<SignUpFormValues, 'signature' | 'licenseFront' | 'licenseBack'>) => ({
  userSignatureData: {
    file: signature,
    path: `signatures/${auth.currentUser.uid}${getExtension(signature)}`,
  },

  userLicenseFrontData: {
    file: licenseFront,
    path: `licenses/${auth.currentUser.uid}.front${getExtension(licenseFront)}`,
  },

  userLicenseBackData: {
    file: licenseBack,
    path: `licenses/${auth.currentUser.uid}.back${getExtension(licenseBack)}`,
  },
});

const createFirestoreUser = async (
  email: string,
  password: string,
): Promise<void> => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw new MMDError('Cannot create user in firebase', err);
  }
};

async function signup({
  email,
  password,
  signature,
  licenseBack,
  licenseFront,
  confirmPassword: _,
  ...formValues
}: SignUpFormValues): Promise<void> {
  let userDocs: UserDocData[] | null = null;

  try {
    if (auth && auth.currentUser) {
      await auth.signOut();
    }

    const { lat, lng } = await extractCoordinatesFromAddress({
      city: formValues.city,
      state: formValues.state,
      zipCode: formValues.zipCode,
      address: formValues.address,
    });

    await createFirestoreUser(email, password);

    const { userSignatureData, userLicenseFrontData, userLicenseBackData } =
      createUserFilesData({ signature, licenseFront, licenseBack });

    userDocs = [userSignatureData, userLicenseFrontData, userLicenseBackData];

    await saveUserDocsInFirebaseStorage(userDocs);

    await saveUserDataInFirestore({
      ...formValues,
      email,
      lat,
      lng,
      signature: userSignatureData.path,
      licenseFront: userLicenseFrontData.path,
      licenseBack: userLicenseBackData.path,
    });

    await saveUserInAPI({ email, ...formValues });

    alert('Sign up successfully, Please check your email.');

    await auth.signOut();
  } catch (err) {
    await onSignUpError(err, email, password, userDocs);
  }
}

const signupWatcher = createSingleEventSaga<
  Dictionary<SignUpFormValues>,
  void,
  MyAction<Dictionary<SignUpFormValues>>
>({
  takeEvery: Types.REQUEST_SIGNUP,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: Creators.successSignup,
  errorAction: ErrorActions.setError,
  action: signup,
});

function* signupSuccessWatcher() {
  while (yield take(Types.SUCCESS_SIGNUP)) {
    yield put(push('/'));
  }
}

export const signupSagas = [signupWatcher, signupSuccessWatcher];

export const PHONE_REG_EXP_ERROR = 'Phone should match a format (999) 999-9999';

export const PHONE_REG_EXP = /^\(\d{3}\) \d{3}-\d{4}$/;

export const getSignupFormValidationSchema = (
  credentials,
  currentStep: 1 | 2,
) => {
  if (currentStep === 1) {
    return Yup.object().shape({
      email: Yup.string()
        .trim()
        .email('Email field has an invalid format')
        .required('Email field is required'),
      password: Yup.string()
        .required(
          'Password must contain at least 8 characters, including UPPER/lowercase, number, and special character',
        )
        .matches(
          PASSWORD,
          'Password must contain at least 8 characters, including UPPER/lowercase, number, and special character',
        ),
      phone: Yup.string().matches(PHONE_REG_EXP, PHONE_REG_EXP_ERROR),
      confirmPassword: Yup.string()
        .required('Please make sure you entered correct password')
        .oneOf(
          [Yup.ref('password'), null],
          'Please make sure you entered correct password',
        ),
      terms: Yup.string()
        .trim()
        .oneOf(
          ['true'],
          'You must agree to the terms and conditions in order to use the application',
        ),
      state: Yup.string()
        .trim()
        .test(
          'is-state-valid',
          'The US state abbreviation entered is not valid',
          (value) => isValidState(value, { caseInsensitive: true }),
        ),
      zipCode: Yup.string()
        .length(5, 'Please zip code using 5 digits')
        .matches(/^\d+$/, 'Please enter a valid zip code')
        .required('Zip code field is required'),
      ssn: Yup.string()
        .trim()
        .matches(/^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/, 'SSN must have 9 numbers'),

      birthday: Yup.string().required('Birthday field is required'),
      credential: Yup.string().required('Credential field is required'),
      pln: Yup.string().required('Pln field is required'),
      // dea: Yup.string().required('Dea field is required'),
      npi: Yup.string().when('credential', ([credential], schema) => {
        const selectedCredential = credentials.find(
          ({ label }) => label === credential,
        );

        if (!selectedCredential?.npiisrequired) {
          return schema;
        }

        return schema
          .required('NPI number has to have exactly 10 digits')
          .min(10, 'NPI number has to have exactly 10 digits')
          .test(
            'is-npi-valid',
            'NPI number is invalid or something went wrong with NPI validation',
            validateNpi,
          );
      }),
    });
  } else {
    return Yup.object().shape({
      licenseBack: Yup.string().required('Front ID image is required'),
      licenseFront: Yup.string().required('Back Id image is required'),
      signature: Yup.string().required('Signature is required'),
    });
  }
};

const validateNpi = async (npi: string) => {
  try {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const result = await fetch(`${AUTH_API_URL}/validate-npi`, {
      headers,
      method: 'POST',
      body: JSON.stringify({ npi }),
    });

    const response = await result.json();

    store.dispatch(Creators.npiDown({ npiDown: response.npiDown }));
    //api down
    if (response.npiDown === true) {
      return true;
    }

    const npiMessage = response.msg;

    if (!npiMessage.includes('INVALID')) {
      store.dispatch(Creators.isDataNpiCheck({ isDataNpiCheck: '' }));
      return true;
    }

    const isNeedToSetNpiError = [
      'FIRSTNAME',
      'LASTNAME',
      'ADDRESS',
      'CITY',
      'STATE',
      'ZIPCODE',
    ].some((npiPart) => npiMessage.includes(npiPart));

    if (isNeedToSetNpiError) {
      store.dispatch(Creators.isDataNpiCheck({ isDataNpiCheck: npiMessage }));
    }

    return false;
  } catch (err) {
    return false;
  }
};
